<template>
   <div class="find-input">
       <mobile-header></mobile-header>
        <div  class="home-container" v-show="findStatus == 0">

            <div :style="{'height': headerHeight + 'px'}"></div>
            <div class="column-center">
                    <div class="title">找回照片</div>

                    <input v-model="orderId" type="text" class="input" placeholder="请输入订单号查询">
                    <van-button class="find xs" :disabled="orderId.length <= 0" :style="{'opacity': orderId.length <= 0 ? 0.4 : 1}" @click="$findPicture">找回</van-button>
                    <div class="error-text nr" v-show="!(errorText == '') && findStatus != 1">
                        {{errorText}}
                    </div>
                    <div class="download-box column" v-show="findStatus == 1">
                        <div class="primary find-tips xs" >已查询到订单，照片即将自动下载，也可点击下方按钮下载</div>
                        <el-button class="down-load-btn xs" @click="downloadPicture">
                            下载照片
                        </el-button>
                    </div>
                    <!-- <div id="img-show" style="max-width: 100px; max-height: 200px"></div> -->
                    <div class="row">
                        <div class="xs order-wx-progress" style="align-self: flex-start;">
                            订单号：
                        </div>
                        <div class="xs order-wx-progress" style="text-align: left;">
                            微信支付 > 查看订单详情 > 商品<br>
                            支付宝支付 > 查看订单详情 > 商品
                        </div>
                    </div>
            </div>
        </div>
        <div class="picture-container" :style="{'background-color': btnShow ? 'white' : '#eff3f4'}" v-show="findStatus == 1" >
            <div class="main">
                <div class="tips" v-show="!btnShow">{{btnText}}</div>
                <img class="exported-image" :src="base64DPI300" alt="">
            </div>
            <div class="control row-center">
                <van-button class="long-btn row-center br60" v-show="btnShow">{{btnText}}</van-button>
                <img class="hidden-image" @click="clickDownload" :style="{'heihgt': phoneHeight + 'px'}"  :src="base64DPI300" alt="exported-image">
            </div>
        </div>
   </div>
</template>

<script>
import "lib-flexible/flexible";
import {findPicture, operationLog} from '@API/app';
import {changeDpiDataUrl} from "changedpi"
import {throttle} from "@U/tool"
import MobileHeader from '@C/MobileHeader'
import axios from 'axios'
import JSMD5 from 'js-md5'
export default {
    data() {
        return {
            headerHeight: 0,
            orderId: '',
            resultPicture: "",
            findStatus: 0,
            errorText: '',
            spec_width: 0,
            spec_height: 0,
            color: "",
            title: "",
            dpi: 300,
            base64DPI300: "",
            phoneHeight: 667,
            btnText: '长按图片保存证件照',
            btnShow: false,
        }
    },
    components: {
        MobileHeader
    },
    created() {
        // 防抖节流
        this.$findPicture = throttle(this.$findPicture, 500, this)
    },
    mounted() {
        let u = navigator.userAgent;
        this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        let ua = navigator.userAgent.toLowerCase(); 
        if(ua.indexOf('baidu') != -1) {
            this.platform = 'baidu'
            if(this.isiOS) {
                this.btnText = "点击保存证件照"
                this.btnShow = true
            }
        }
        this.phoneHeight = window.innerHeight
        this.$nextTick(() => {
            this.headerHeight = document.getElementById("mobile-header").clientHeight;
        })
        localStorage.setItem("bd_vid", this.$route.query.bd_vid);
        this.$operationLog({
            btnId: "pc-h5-retrieve photos",
            type: '0'
        })
        
    },
    methods: {
        // 埋点
        $operationLog(data) {
            operationLog({
                ...data
            }).then(res => {

            })
        },
        $findPicture() {
            
            if(this.orderId == '') {
                this.$message.error("订单号不能为空")
                return;
            }
            this.$operationLog({
                btnId: "pc-h5-click-retrieve",
                type: '0'
            })
            findPicture({
                id: this.orderId
            }).then(res => {
                if(res.success == "1") {
                    if(res.obj.status == 1) {
                        this.findStatus = 1;
                        this.resultPicture = res.obj.uidFolder;
                        let orderInfo = JSON.parse(res.obj.extendJson);
                        this.pxSizeX = orderInfo.pxSizeX;
                        this.pxSizeY = orderInfo.pxSizeY;
                        this.color = orderInfo.bgcolor;
                        this.title = orderInfo.goodsName;
                        this.dpi = orderInfo.dpi;
                        this.downloadPicture()
                    }
                    else {
                        this.findStatus = 0
                        this.errorText = "该订单未支付或异常"
                    }
                    // this.downloadPicture()
                }
            }).catch(err => {
                this.findStatus = 0;
                if(err.success == "10005") {
                    this.errorText = "查询不到订单，请输入正确的订单号"
                }
            })
        },
        downloadPicture() {
            let that = this
            let picture_link = document.createElement('a');
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.setAttribute("id", "result-picture")
            canvas.width = this.pxSizeX;
            canvas.height = this.pxSizeY;
            let image = new Image();
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.resultPicture;
            image.width = this.pxSizeX;
            image.height = this.pxSizeY;
            ctx.fillStyle = this.color;
            ctx.fillRect(0, 0, this.pxSizeX, this.pxSizeY)
            
            image.onload = () => {
                // document.querySelector("#picture-container").appendChild(canvas)
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let dataURL = canvas.toDataURL('image/jpeg', 1.0);
                let dataURL300DPI = changeDpiDataUrl(dataURL, that.dpi)
                this.base64DPI300 = dataURL300DPI;
                this.findStatus = 1;
                if(that.platform == 'baidu') {
                    let resultBlob = that.dataURItoBlob(dataURL300DPI);
                    if(that.isiOS) {
                        that.uploadFile(new File([resultBlob], new Date().getTime() + '.jpg'))
                    }
                }
            }
            this.$operationLog({
                btnId: "pc-download auto",
                type: "0"
            })
        },
        uploadFile(file) {
            let params = new FormData();
            params.append("file", file);
            let time = new Date().getTime();
            params.append("_time", time);
            params.append("_sign", JSMD5(`lx0e@_time=${time}n0*32`));
            let picture_link = document.createElement('a');
            return new Promise((resolve) => {
                axios
                    .post("http://api.cameramagic.shunmakeji.com/v1/uploadFile?_model=pc", params, {
                        headers: { 
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(({ data }) => {
                        if (data.success == 1) {
                            // console.log(data)
                            this.resultPicture = data.obj
                            // picture_link.href = data.obj;
                            // picture_link.target = "_blank";
                            // picture_link.download = this.title + ".jpg";
                            // picture_link.click();
                            if(this.isiOS) {
                                location.href = data.obj;
                            }
                        } else {
                            this.$toast("上传失败，请重新上传");
                            // this.showUploadPicture = false;
                            // this.fileList = [];
                        }
                    }).catch(err => {
                        // console.log("上传出错")
                        this.$toast("上传出错")
                        // this.showUploadPicture = false;
                        // this.fileList = [];
                    });
            });
        },
        /* base64转Blob对象 */
        dataURItoBlob(data) {
            let byteString;
            if(data.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(data.split(',')[1])
            }else {
                byteString = unescape(data.split(',')[1])
            }
            let mimeString = data
                .split(',')[0]
                .split(':')[1]
                .split(';')[0];
            let ia = new Uint8Array(byteString.length)
            for( let i = 0; i < byteString.length; i += 1) {
                ia[i] = byteString.charCodeAt(i)
            }
            return new Blob([ia], {type: mimeString})
        },
        clickDownload() {
            if(this.resultPicture == '') {
                return
            }
            if( this.platform == 'baidu') {
                if(this.isiOS) {
                    location.href = this.resultPicture;
                }
                else {
                    // let picture_link = document.createElement('a');
                    // picture_link.href = this.resultPicture;
                    // picture_link.target = "_blank";
                    // picture_link.download = this.title + ".jpg";
                    // picture_link.click();
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .find-input {
        .home-container{
            background: white;
        //    margin-top: 102px;
            height: calc(100vh - 1px);
            .line{
                background: #E5E5E5;
    
                width: 100%;
                height: 1px;
            }
            .title {
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: bold;
                color: #111111;
                line-height: 67px;
                margin-top: 64px;
                margin-bottom: 48px;
                font-size: 48px;
            }
            .input {
                width: 430px;
                border-radius: 4px;
                border: 1px solid #E5E5E5;
                font-family: Lato-Bold, Lato;
                font-weight: bold;
                color: #111111;
                line-height: 39px;
                padding:25px;
                appearance: button;
                -moz-appearance: button; /* Firefox */
                -webkit-appearance: button; /* Safari 和 Chrome */
            }
            .find{
                width: 480px;
                height: 60px;
                background: $--color-primary;
                border-radius: 8px;
                margin-top: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 60px;
            }
            .error-text {
                color: #E65351;
                font-size: 28px;
                margin-top: 96px;
            }
            .download-box {
                margin-top: 48px;
                padding: 63px;
                .tips {
                    margin-top: 48px;
                    line-height: 25px;
                }
                .down-load-btn {
                    color: $--color-primary;
                    border: 1px solid $--color-primary;
                    margin-top: 24px;
                }
            }
            .order-wx-progress {
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #808080;
                line-height: 42px;
                margin-top: 48px;
            }
            .order-ali-progress{
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #808080;
                line-height: 42px;
                margin-top: 48px;
                margin-top: 4px;
            }
    
        }
        .picture-container {
            height: calc(100vh - 101px);
            min-height: calc(100vh - 101px);
            background-color: #eff3f4;
            margin-top: 100px;
            .main {
                height: calc(100% - 201px);
                background-color: #eff3f4;
                padding: 54px 32px;
                box-sizing: border-box;
                .exported-image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-style: none;
                }
            }
            .control {
                // background-color: white;
                padding: 48px;
                .long-btn {
                    border-radius: 120px;
                    height: 100px;
                    width: 100%;
                    color: white;
                    background-color: $--color-primary;
                }
                .hidden-image {
                    position: absolute;
                    height: 100vh;
                    width: 100vw;
                    object-fit: cover;
                    opacity: 0;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
</style>